import { yellow, blue, grey } from "@mui/material/colors";
import { Avatar, Button } from "@mui/material";
import React from "react";
import { redirectTo } from "../../routes/Routes";

import { Stack } from "@mui/material";
export default function InfoPanel() {
  return (
    <div>
      <div className="d-sm-block d-md-none">
        <div>
          <div className="d-flex justify-content-center">
            <Avatar sx={{ width: 60, height: 60, bgcolor: blue[500] }}>
              <i style={{ fontSize: 24 }} class="fas fa-users    "></i>
            </Avatar>
          </div>
          <div className="text-center">
            <Button
              sx={{ color: blue[500] }}
              onClick={() => {
                redirectTo("theTeam");
              }}
            >
              the team
            </Button>
          </div>
        </div>
        {/* <div>
          <div className="d-flex justify-content-center">
            <Avatar sx={{ width: 80, height: 80, bgcolor: pink[500] }}>
              <Announcement sx={{ height: 50, width: 50 }} />
            </Avatar>
          </div>
          <div className="text-center">
            <Button sx={{ color: pink[500] }}>testimonials</Button>
          </div>
        </div> */}

        <div className="mt-2">
          <div className="d-flex justify-content-center">
            <Avatar sx={{ width: 60, height: 60, bgcolor: yellow[700] }}>
              <i style={{ fontSize: 24 }} class="fas fa-globe    "></i>
            </Avatar>
          </div>
          <div className="text-center">
            <Button
              sx={{ color: yellow[700] }}
              onClick={() => {
                redirectTo("aboutus");
              }}
            >
              about us
            </Button>
          </div>
        </div>
      </div>
      <div className="d-none d-md-block d-lg-none">
        <div className="d-flex justify-content-center p-5">
          <Stack direction="row" spacing={5}>
            <div>
              <div className="d-flex justify-content-center">
                <Avatar sx={{ width: 100, height: 100, bgcolor: blue[500] }}>
                  <i style={{ fontSize: 44 }} class="fas fa-users    "></i>
                </Avatar>
              </div>
              <div className="text-center">
                <Button
                  sx={{ color: blue[500] }}
                  onClick={() => {
                    redirectTo("theTeam");
                  }}
                >
                  the team
                </Button>
              </div>
            </div>

            {/* <div>
              <div className="d-flex justify-content-center">
                <Avatar sx={{ width: 100, height: 100, bgcolor: pink[500] }}>
                  <Announcement sx={{ height: 300 }} />
                </Avatar>
              </div>
              <div className="text-center">
                <Button sx={{ color: pink[500] }}>testimonials</Button>
              </div>
            </div> */}

            <div className="">
              <div className="d-flex justify-content-center">
                <Avatar sx={{ width: 100, height: 100, bgcolor: yellow[700] }}>
                  <i style={{ fontSize: 44 }} class="fas fa-globe    "></i>
                </Avatar>
              </div>
              <div className="text-center">
                <Button
                  sx={{ color: yellow[700] }}
                  onClick={() => {
                    redirectTo("aboutus");
                  }}
                >
                  about us
                </Button>
              </div>
            </div>

            {/* <div>
              <div className="d-flex justify-content-center">
                <Avatar sx={{ width: 100, height: 100, bgcolor: yellow[700] }}>
                  <Handshake sx={{ height: 70, width: 70 }} />
                </Avatar>
              </div>
              <div className="text-center">
                <Button sx={{ color: yellow[700] }}>OUR PARTNERS</Button>
              </div>
            </div> */}
          </Stack>
        </div>
      </div>
      <div
        className="d-none d-lg-block   p-3 mt-3"
        style={{ backgroundColor: grey[50] }}
      >
        <div className="d-flex  justify-content-center">
          <Stack direction="row" spacing={5}>
            <div>
              <div className="d-flex justify-content-center">
                <Avatar sx={{ width: 100, height: 100, bgcolor: blue[500] }}>
                  <i style={{ fontSize: 54 }} class="fas fa-users    "></i>
                </Avatar>
              </div>
              <div className="text-center">
                <Button
                  sx={{ color: blue[500] }}
                  onClick={() => {
                    redirectTo("theTeam");
                  }}
                >
                  the team
                </Button>
              </div>
            </div>

            {/* <div>
              <div className="d-flex justify-content-center">
                <Avatar sx={{ width: 100, height: 100, bgcolor: pink[500] }}>
                  <Announcement sx={{ height: 70, width: 70 }} />
                </Avatar>
              </div>
              <div className="text-center">
                <Button sx={{ color: pink[500] }}>testimonials</Button>
              </div>
            </div> */}

            <div>
              <div className="d-flex justify-content-center">
                <Avatar sx={{ width: 100, height: 100, bgcolor: yellow[700] }}>
                  <i style={{ fontSize: 54 }} class="fas fa-globe    "></i>
                </Avatar>
              </div>
              <div className="text-center">
                <Button
                  sx={{ color: yellow[700] }}
                  onClick={() => {
                    redirectTo("aboutus");
                  }}
                >
                  about us
                </Button>
              </div>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
}
