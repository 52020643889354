import { Avatar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { TheTeam } from "../../services/labels";
import "./IndividualBio.css";

export default function IndidualBio() {
  const defaultData = { avatar: "", title: "", role: "", paragraphs: [] };
  const { id } = useParams();

  const [bio, setBio] = useState(defaultData);

  const GetBio = () => {
    setBio(TheTeam[id]);
  };

  useEffect(() => {
    GetBio();
  }, []);
  return (
    <div>
      <Container>
        <div className="backDrop d-flex align-items-center justify-content-center text-white">
          <div className="d-none d-lg-block">
            <div>
              <Typography
                variant="h4"
                textTransform={"uppercase"}
                letterSpacing={2}
                textAlign={"center"}
              >
                {bio.title}
              </Typography>
              <Typography variant="subtitle2" textAlign={"center"}>
                {bio.role}
              </Typography>
            </div>
          </div>
          <div className="d-none d-md-block d-lg-none">
            <div>
              <Typography
                variant="h6"
                textTransform={"uppercase"}
                letterSpacing={2}
                textAlign={"center"}
              >
                {bio.title}
              </Typography>
              <Typography variant="subtitle2" textAlign={"center"}>
                {bio.role}
              </Typography>
            </div>
          </div>
          <div className="d-sm-block d-md-none">
            <div>
              <div className="text-end pe-3">
                <Typography
                  variant="body1"
                  textTransform={"uppercase"}
                  letterSpacing={1}
                >
                  {bio.title}
                </Typography>
                <Typography variant="caption">{bio.role}</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block">
          <div className="bg-transparent ps-5 mb-3" style={{ marginTop: -65 }}>
            <Avatar src={bio.avatar} sx={{ width: 150, height: 150 }} />
          </div>
        </div>
        <div className="d-none d-md-block d-lg-none">
          <div className="bg-transparent ps-4 mb-3" style={{ marginTop: -65 }}>
            <Avatar src={bio.avatar} sx={{ width: 120, height: 120 }} />
          </div>
        </div>
        <div className="d-sm-block d-md-none">
          <div className="bg-transparent ps-3 mb-3" style={{ marginTop: -50 }}>
            <Avatar src={bio.avatar} sx={{ width: 100, height: 100 }} />
          </div>
        </div>

        <div>
          {bio.paragraphs.map((text, i) => (
            <Typography key={i} variant="body2" gutterBottom>
              {text}
            </Typography>
          ))}
        </div>
      </Container>
    </div>
  );
}
