import doors from "../assets/carouselImages/img5.jpg";

import perimeterFences from "../assets/carouselImages/img12.jpg";

import resDoors1 from "../assets/images/resDoors1.jpg";
import resDoors2 from "../assets/images/resDoors2.jpg";
import resDoors3 from "../assets/images/resDoors3.jpg";
import indDoors1 from "../assets/images/indDoors1.jpg";
import loadTechnology from "../assets/images/loadTechnology.jpg";
import loadTechnology2 from "../assets/images/loadTechnology2.jpg";
import slidingDoor from "../assets/carouselImages/slidingDoor.jpg";
import shutter1 from "../assets/carouselImages/img6.jpg";
import shutter2 from "../assets/carouselImages/img7.jpg";
import shutter3 from "../assets/carouselImages/img8.jpg";
import highSpeedDoor from "../assets/carouselImages/img3.jpg";
import sectionalDoor from "../assets/carouselImages/img1.jpg";
import liftBarrier from "../assets/carouselImages/liftBarrier.jpg";
import liftBarrier2 from "../assets/carouselImages/liftBarrier2.jpg";
import bollards from "../assets/images/bollards.jpg";
import bollards2 from "../assets/images/bollards2.jpg";
import bollards3 from "../assets/images/bollards3.jpg";
import frames from "../assets/images/frames.jpg";

const categories = [
  {
    image: doors,
    name: "Doors",
    id: "doors",
    description1:
      "Buildings are almost incomplete without doors. For modern and new construction residential and industrial buildings, there's a door that suits every purpose for which it was designed.",
    types: [
      {
        images: [resDoors1, resDoors2, resDoors3],
        name: "Residential Doors",
        description1:
          "Living with family and friends would require doors that are perfectly finished and furnished with ultra-modern designs, quality, colour, a wide range of equipment and functions. ",
        description2:
          "Our residential doors are a delight to have in every home. This is due to the level of multifaceted functions that these doors possess other than a means of entrance and exit.",
      },
      {
        images: [indDoors1],
        name: "Industrial Doors",
        description1:
          "Industries, whether small, medium, or large, need high-quality industrial doors for security, safety, and class. ",
        description2:
          "With high cutting edge and operator technology, our industrial doors are a perfect fit for any type of type of industrial settings.",
      },

      {
        name: "Sliding doors",
        description1:
          "Fire and smoke-tight sliding doors by Hörmann are available as singleand double-leaf versions or as telescopic fire sliding doors and separate large fire sections.",
        images: [slidingDoor],
      },
      {
        name: "High Speed doors",
        images: [highSpeedDoor],
        description1:
          "Hörmann high-speed doors are used both inside and as exterior doors to optimise the flow of traffic, improve the room climate and save energy.",
        description2:
          "Frequency converter control as standard to take stress off the mechanical door elements, for nearly wear-free, quiet door travel",
      },
      {
        name: "Sectional Doors",
        description1:
          "Hörmann’s side sliding sectional door opens to the side instead of upwards. This is particularly advantageous if, for example, the garage ceiling has to remain free to store items (such as ladders).",
        images: [sectionalDoor],
      },
      {
        name: "Rolling Shutters",
        images: [shutter1, shutter2, shutter3],
        description1:
          "Thanks to a simple construction with just a few components, rolling shutters are both economical and robust. Hörmann offers the right equipment for every application in industry, retail and trade.",
      },
      //{ name: "Sliding Gates" },
    ],
  },
  {
    name: "Perimeter Protection Systems",
    image: perimeterFences,
    id: "perimeterProtectionSystems",
    description1:
      "Our perimeter protection system such as bollards, road blockers, barriers and tyre killers offers wide range of suitable solutions for your security and safety needs.",
    description2:
      "Our perimeter protection system covers company entrance and exit areas, shopping malls and public spaces.",
    types: [
      {
        name: "Lift Barriers",
        images: [liftBarrier, liftBarrier2],
        description1:
          "Lift barriers safeguard controlled entrances and exits up to 10 meters in width. They are suitable for very frequent use and meet high safety requirements.",
      },
      {
        name: "Bollards",
        description1: "",
        images: [bollards, bollards2, bollards3],
      },
      // { name: "Automatic Bollards" },
      // { name: "Fixed Bollards" },
      // { name: "Semi-Automatic Bollards" },
      // { name: "Removable Bollards" },
    ],
  },
  //{ name: "Shutters", id: "shutters", image: doors3 },
  {
    name: "Loading Technology",
    image: loadTechnology,
    id: "loadingTechnology",
    description1:
      "Loading bay equipment is critical to the efficient and safe handling of goods. Levellers, shelters, loadhouses, and other docking equipment make loading and unloading more convenient and environmentally friendly.",
    description2:
      "The main purpose of loading bays is to maintain order and safety, and most importantly, offer convenience in moving goods inside and outside of the warehouse.",
    types: [
      {
        name: "Loading Houses",
        description1:
          "The main purpose of loading bays is to maintain order and safety, and most importantly, offer convenience in moving goods inside and outside of the warehouse.",
        images: [loadTechnology2],
      },
      // { name: "Dock Levellers" },
      // { name: "Dock shelters/ Dock Seals" },
    ],
  },
  {
    name: "Frames",
    id: "frames",
    image: frames,
    description1:
      "Our frames are optimally modern to give you solution for fitting in partition walls. Our standard, quality and special frames ",
    description2:
      "These frames covers everything from standard frames, profile frames and special frames.Special frames and versions include:",
    //  types: [
    //    { name: "Easy-to-transport knock-down frames" },
    //    { name: "Hospital frames" },
    //    { name: "Nursery school door frames" },
    //    { name: "Sports hall frames" },
    //    { name: "Lift frames" },
    //    { name: "Sliding door frames" },
    //    { name: "Double-action door frames" },
    //    { name: "Renovation frames" },
    //    { name: "Sloping reveal frames" },
    //  ],
  },
];
const productGallery = [];

export { categories, productGallery };
