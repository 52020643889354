import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { Download } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function CardComponent({ img, title, downloadUrl }) {
  return (
    <div className="me-2 mb-2">
      <Card sx={{ width: 345 }}>
        <CardActionArea>
          {(
            <CardMedia
              component="img"
              className="img-fluid"
              image={img}
              alt={title}
            />
          ) || <Skeleton />}
        </CardActionArea>
        <CardActions>
          {(
            <Button
              onClick={() => {
                window.location.assign(downloadUrl);
              }}
              startIcon={<Download />}
            >
              download
            </Button>
          ) || <Skeleton />}
        </CardActions>
      </Card>
    </div>
  );
}
