import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./Routes";

const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((route, i) => (
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
