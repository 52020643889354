import React from "react";
import "./WelcomePage.css";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  CardActions,
} from "@mui/material";
import { Container } from "react-bootstrap";

import { DistributorLabel, WelcomePageText } from "../../services/labels";
import { ArrowForwardIos, Handshake, Store } from "@mui/icons-material";

import whiteWaterMark from "../../assets/Logo/whiteWatermark.png";
import badge from "../../assets/Logo/badge.png";

import MyCarousel from "../../components/Carousel/MyCarousel";
import InfoPanel from "../../components/Info/InfoPanel";
import PictureCards from "../../components/PictureCards/PictureCards";
import card5 from "../../assets/images/card5.jpeg";

import { redirectTo } from "../../routes/Routes";
import ProductDesctiption from "../../components/ProductDescription/ProductDesctiption";

import { green } from "@mui/material/colors";

export default function WelcomePage() {
  return (
    <div>
      <div className="homeBanner d-flex justify-content-center align-items-center shadow-lg ">
        <div className="d-none d-lg-block text-center logoAnimation">
          <img
            src={whiteWaterMark}
            height="150"
            width="250"
            alt="sarnia logo"
            className="mb-3 "
          />

          <Typography color="white" gutterBottom variant="h2" fontWeight={100}>
            {WelcomePageText.title}
          </Typography>

          <Typography color="white" variant="h5">
            {WelcomePageText.subHeading}
          </Typography>
          <Button
            endIcon={<Handshake />}
            variant="contained"
            className="mt-3"
            size="large"
            onClick={() => {
              redirectTo("joinUs");
            }}
          >
            Join us today
          </Button>
        </div>
        <div className="d-none d-md-block d-lg-none text-center logoAnimation">
          <img
            src={whiteWaterMark}
            height="120"
            width="220"
            alt="sarnia logo"
            className="mb-3 "
          />
          <Typography color="white" variant="h3" className="">
            {WelcomePageText.title}
          </Typography>
          <Typography color="white" variant="body1">
            {WelcomePageText.subHeading}
          </Typography>
          <Button
            endIcon={<Handshake />}
            variant="contained"
            className="mt-3"
            size="medium"
            onClick={() => {
              redirectTo("joinUs");
            }}
          >
            Join us today
          </Button>
        </div>
        <div className="d-none d-sm-block d-md-none text-center logoAnimation">
          <img
            src={whiteWaterMark}
            height="100"
            width="200"
            alt="sarnia logo"
            className="mb-3 "
          />
          <Typography color="white" gutterBottom variant="h4" className="">
            {WelcomePageText.title}
          </Typography>
          <Typography color="white" variant="body1">
            {WelcomePageText.subHeading}
          </Typography>
          <Button
            endIcon={<Handshake />}
            variant="contained"
            className="mt-3"
            size="medium"
            onClick={() => {
              redirectTo("joinUs");
            }}
          >
            Join us today
          </Button>
        </div>
        <div className="d-block d-sm-none text-center logoAnimation">
          <img
            src={whiteWaterMark}
            height="80"
            width="180"
            alt="sarnia logo"
            className="mb-3 "
          />
          <Typography color="white" variant="h5" gutterBottom className="">
            {WelcomePageText.title}
          </Typography>

          <Typography color="white" variant="body2">
            {WelcomePageText.subHeading}
          </Typography>
          <Button
            endIcon={<Handshake />}
            variant="contained"
            className="mt-3"
            size="small"
            onClick={() => {
              redirectTo("joinUs");
            }}
          >
            Join us today
          </Button>
        </div>
      </div>
      <section style={{ marginTop: -60 }}>
        <PictureCards></PictureCards>
      </section>
      {/*subtitle */}
      <div className="text-center">
        <div className="d-block d-md-none">
          <Typography
            color="GrayText"
            variant="h6"
            fontWeight={400}
            className="mt-3 mb-3"
          >
            {WelcomePageText.subtitle}
            <Typography variant="body1" color="GrayText">
              {WelcomePageText.subtitle2}
            </Typography>
          </Typography>
        </div>
        <div className="d-none d-md-block d-lg-none">
          <Typography
            color="GrayText"
            variant="h5"
            fontWeight={400}
            className="mt-4 mb-4"
          >
            {WelcomePageText.subtitle}
            <Typography variant="h6" color="GrayText">
              {WelcomePageText.subtitle2}
            </Typography>
          </Typography>
        </div>
        <div className="d-none d-lg-block  ">
          <Typography
            color="GrayText"
            variant="h4"
            fontWeight={400}
            className="mt-5 mb-5"
          >
            {WelcomePageText.subtitle}
            <Typography variant="h6" color="GrayText">
              {WelcomePageText.subtitle2}
            </Typography>
          </Typography>
        </div>
      </div>

      <section>
        <div className="d-none d-lg-block ">
          <div className="row p-3 officeBanner">
            <div className="col-lg-6 d-flex justify-content-center align-items-center border-end">
              <img src={badge} height="200" width="200" alt="brand logo" />
            </div>
            <div className="col-lg-6 d-flex align-items-center text-white">
              <div className="ps-3">
                <Typography variant="h3" gutterBottom>
                  SarniaRock International Limited
                </Typography>
                <div className="col-md-6">
                  <Typography variant="body1">{DistributorLabel}</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-none d-md-block d-lg-none">
          <div className="officeBanner p-4">
            <div className=" d-flex justify-content-center ">
              <img src={badge} height="180" width="180" alt="brand logo" />
            </div>
            <div className=" d-flex justify-content-center text-white mt-3">
              <div className="ps-3 text-center">
                <Typography variant="h4" gutterBottom>
                  SarniaRock International Limited
                </Typography>
                <div className="">
                  <Typography variant="body1">{DistributorLabel}</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-sm-block d-md-none">
          <div className="officeBanner p-4">
            <div className=" d-flex justify-content-center ">
              <img src={badge} height="150" width="150" alt="brand logo" />
            </div>
            <div className=" d-flex justify-content-center text-white mt-3">
              <div className="ps-3 text-center">
                <Typography variant="h5" gutterBottom>
                  SarniaRock International Limited
                </Typography>
                <div className="">
                  <Typography variant="body1">{DistributorLabel}</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-3">
        <Container>
          <Card>
            <CardActionArea>
              <CardMedia component="img" height="500" image={card5} />
              <CardContent>
                <Typography variant={{ md: "h3", sm: "h4", xs: "h5" }}>
                  Hörmanns' officially approved distributor in Africa
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className="mb-4">
              <Button
                onClick={() => {
                  window.location.assign("https://www.hoermann.com/en/");
                }}
                endIcon={<ArrowForwardIos />}
              >
                Visit Hörmann for more{" "}
              </Button>
            </CardActions>
          </Card>
        </Container>
      </section>
      <Container>
        <div className="text-center mt-5">
          <Button
            sx={{ color: green[500], borderColor: green[500], border: 1 }}
            endIcon={<Store />}
            onClick={() => {
              redirectTo("products");
            }}
          >
            check out our products
          </Button>
        </div>
        <MyCarousel />

        <div className="mt-3">
          <ProductDesctiption />
        </div>
      </Container>
      <div className="mt-3">
        <InfoPanel></InfoPanel>
      </div>
    </div>
  );
}
