import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import MyCards from "../../components/myCards/MyCards";
import {
  CurrencyExchange,
  DeliveryDining,
  HourglassBottomRounded,
} from "@mui/icons-material";
import { Typography } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: { position: "top" },
    title: { display: true, text: "2022 FIRST HALF" },
  },
};
const labels = ["January", "February", "March", "April", "May", "June", "July"];
const data = {
  labels,
  datasets: [
    {
      label: "Sales",
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Orders",
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
export default function Dashboard() {
  return (
    <div className="p-3">
      <div className="row">
        <div className="col md-6 border-end">
          <Bar options={options} data={data} />
        </div>
        <div className="col-md-6">
          <Typography>{`TODAY'S REPORT ${new Date().toDateString()}`}</Typography>
          <hr />
          <div className="d-flex flex-wrap">
            <MyCards
              color={"error"}
              title="ORDERS"
              subtitle={24}
              icon={<CurrencyExchange htmlColor="white" />}
            />
            <MyCards
              margin="ms-2"
              color={"warning"}
              title="PROCESSING"
              subtitle={24}
              icon={<HourglassBottomRounded htmlColor="gray" />}
            />
            <MyCards
              margin=""
              color={"success"}
              title="DELIVERED"
              subtitle={24}
              icon={<DeliveryDining htmlColor="white" />}
            />
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
