import { Avatar, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { categories } from "../../services/imageRolls";
import "./ProductCategoryView.css";

export default function ProductCategoryView() {
  const [product, setProduct] = useState({ types: [] });
  const { id } = useParams();

  useEffect(() => {
    const category = categories.find((c) => c.id === id);
    setProduct(category);
  }, []);

  return (
    <div className="mt-3">
      <Container className="p-3 shadow-lg">
        {/**
         * The product banner
         */}
        <Row>
          <div className="col-md-6 d-flex align-items-center p-4">
            <div>
              <div className="d-none d-lg-block">
                <Typography variant="h2" fontWeight={100} gutterBottom>
                  {product.name}
                </Typography>
              </div>
              <div className="d-none d-md-block d-lg-none">
                <Typography variant="h3" fontWeight={100} gutterBottom>
                  {product.name}
                </Typography>
              </div>
              <div className="d-sm-block d-md-none">
                <Typography variant="h4" fontWeight={100} gutterBottom>
                  {product.name}
                </Typography>
              </div>
              <Typography variant="body2">{product.description1}</Typography>
            </div>
          </div>
          <div className="col-md-6 bannerImage">
            <img
              src={product.image}
              className="img-fluid rounded-3 shadow-sm"
              alt={product.name}
            />
          </div>
        </Row>
        <hr />
        {product.types && product.types.length > 0 ? (
          <div>
            {/* <div>
              <Typography>Types of {product.name}</Typography>
            </div> */}
            {product.types.map((item, index) => (
              <div key={index} className=" mb-3">
                <div>
                  <div className="p-3">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="text-center text-uppercase"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className="text-center"
                    >
                      {item.description1}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className="text-center"
                    >
                      {item.description2 ? item.description2 : ""}
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="">
                      {item.images && item.images.length > 0 ? (
                        <Stack
                          direction={{
                            xs: "column",
                            md: "column",
                            lg: "row",
                          }}
                          spacing={{ xs: 1, md: 2, lg: 4 }}
                        >
                          {item.images.map((item2, index) => (
                            <Avatar
                              src={item2}
                              variant="rounded"
                              sx={{ width: 300, height: 300 }}
                            />
                          ))}
                        </Stack>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </Container>
    </div>
  );
}
