import { Box, Tab, Typography, Button, MobileStepper } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Row } from "react-bootstrap";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PRODUCTDESCRIPTION } from "../../services/labels";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import card1 from "../../assets/images/card1.jpeg";

export default function ProductDesctiption() {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = PRODUCTDESCRIPTION.length;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div>
      <div className="d-none d-lg-block">
        <Row className="">
          <div className="col-md-6">
            <img src={card1} alt="product description" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {PRODUCTDESCRIPTION.map((item, index) => (
                      <Tab label={item.title} value={index} />
                    ))}
                  </TabList>
                </Box>
                {PRODUCTDESCRIPTION.map((item, index) => (
                  <TabPanel value={index}>
                    {item.texts.map((text, index2) => (
                      <Typography key={index2} gutterBottom>
                        {text}
                      </Typography>
                    ))}
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          </div>
        </Row>
      </div>
      <div className="d-block d-lg-none">
        <div className="d-flex justify-content-center">
          <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <Paper
              square
              elevation={0}
              sx={{
                display: "flex",
                alignItems: "center",
                height: 50,
                pl: 2,
                bgcolor: "background.default",
              }}
            >
              <Typography variant="h5">
                {PRODUCTDESCRIPTION[activeStep].title}
              </Typography>
            </Paper>
            <Box sx={{ maxWidth: 400, width: "100%", p: 2 }}>
              {PRODUCTDESCRIPTION[activeStep].texts.map((text, index) => (
                <Typography gutterBottom>{text}</Typography>
              ))}
            </Box>
            <MobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
        </div>
      </div>
    </div>
  );
}
