import { ArrowForwardIos } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";
import { categories } from "../../services/imageRolls";
import { green } from "@mui/material/colors";
import { redirectTo } from "../../routes/Routes";

export default function PreProducts() {
  return (
    <div className="mt-3">
      <Container className="p-3 shadow-lg">
        <div className="">
          <div className="p-4">
            <div className="d-none d-md-block">
              <Typography
                align="center"
                variant="h3"
                sx={{ fontWeight: 100, color: green[400] }}
              >
                OUR PRODUCTS
              </Typography>
            </div>
            <div className="d-sm-block d-md-none">
              <Typography
                align="center"
                variant="h5"
                sx={{ fontWeight: 100, color: green[400] }}
              >
                OUR PRODUCTS
              </Typography>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {categories.map((item, index) => (
              <Card key={index} sx={{ maxWidth: 349 }} className="me-3 mb-3">
                <CardMedia component="img" image={item.image} height="200" />
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className="text-uppercase"
                    sx={{ fontWeight: 100 }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body2">{item.description1}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    sx={{ color: green[400] }}
                    endIcon={<ArrowForwardIos />}
                    onClick={() => {
                      redirectTo(`products/${item.id}`);
                    }}
                  >
                    View more
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
