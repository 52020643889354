import { Button, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { httpService, loggedInUser } from "../../services/services";
import defaultProduct from "../../assets/images/defaultProduct.png";
import { Login, Star } from "@mui/icons-material";
import Swal from "sweetalert2";

export default function OrdersPage() {
  const [products, setProducts] = useState([]);
  const GetProducts = async () => {
    const path = "viewProducts";
    const res = await httpService.get(path);
    setProducts(res.data.products);
  };

  const AddToCart = async () => {
    if (!loggedInUser) {
      Swal.fire({
        icon: "info",
        title: "Not signed in",
        text: "You are not signed in, hence you cannot add this product to your cart items.",
        confirmButtonText: "Go to sign in page",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.assign("signIn?from=shopProducts");
        }
      });
    }
  };
  useEffect(() => {
    GetProducts();
  }, []);
  return (
    <div>
      <Container className="shadow-lg rounded-3 mt-3 p-3">
        <Typography
          color="GrayText"
          variant="h5"
          className="text-center mt-2 mb-2"
        >
          SHOP NOW FOR SARNIA PRODUCTS
        </Typography>
        <hr />
        <div className="d-flex flex-wrap">
          {products.map((prod, i) => (
            <div
              key={i}
              className={` col-md-3 ${
                i !== products.length - 1 ? "border-end mb-3" : ""
              }`}
            >
              <div className="">
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      prod.images.length > 0
                        ? prod.images[
                            Math.floor(Math.random() * prod.images.length)
                          ]
                        : defaultProduct
                    }
                    alt={prod.title}
                    height={180}
                    width={300}
                    className="img-responsive"
                  />
                </div>
                <div className="border border-light p-3 mt-3">
                  <div>
                    <Typography variant="h6" color="CaptionText">
                      {prod.title}
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <Typography>{`₦${prod.price.toLocaleString()}`}</Typography>
                    <div>
                      <Star color="success" />
                      <Star color="success" />
                      <Star color="success" />
                      <Star color="success" />
                      <Star color="success" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <IconButton onClick={AddToCart}>
                        <i class="fas fa-cart-plus    "></i>
                      </IconButton>
                    </div>
                    <div>
                      <Button endIcon={<Login />} color="error">
                        Order now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
