import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";

import blackWatermark from "../assets/Logo/blackWatermark.png";
import { NavLabels } from "../services/labels";

export default function NavigationBar() {
  return (
    <div>
      <Navbar bg="light" expand="lg" fixed="top" className="mb-5">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src={blackWatermark}
              width="60"
              height="40"
              className="d-align-block align-top"
              alt="sarnia logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {NavLabels.map((nav, i) => (
                <Nav.Link href={nav.path} target={nav.target}>
                  {nav.label}
                </Nav.Link>
              ))}
            </Nav>
            {/* <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/dashboard">Dashboard</Nav.Link>
              <NavDropdown title="Products" id="basic-nav-dropdown">
                <NavDropdown.Item href="/products">
                  View Products
                </NavDropdown.Item>
                <NavDropdown.Item href="/Orders">Orders</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="ms-auto">
              {loggedInUser ? (
                <>
                  <Nav.Link component="button" onClick={handleLogout}>
                    Sign Out
                  </Nav.Link>
                </>
              ) : (
                <Nav.Link href="/signIn">Sign In</Nav.Link>
              )}
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
