import React from "react";
import { Container, Row } from "react-bootstrap";
import "./ContactUs.css";

import { Typography } from "@mui/material";
import { blue, pink } from "@mui/material/colors";
import { ADDRESS } from "../../services/labels";

import Enquiry from "../../components/Enquiry";

export default function ContactUs() {
  return (
    <div className="mt-3">
      <Container className="shadow-lg rounded-3 ">
        <div className="d-none d-lg-block">
          <Row>
            <div className="col-md-6 p-5 d-flex align-items-center">
              <div className="contactText">
                <Typography
                  className="mb-3"
                  variant="h3"
                  sx={{ color: blue[900] }}
                >
                  CONTACT US
                </Typography>

                <div>
                  <Typography
                    className="mb-3"
                    variant="h4"
                    sx={{ color: blue[700] }}
                  >
                    {ADDRESS.title}
                    <span className="ms-2" style={{ color: pink[500] }}>
                      <i class="fas fa-map-marker-alt    "></i>
                    </span>
                  </Typography>
                  <Typography variant="body1">{ADDRESS.p1}</Typography>
                  <Typography variant="body1">{ADDRESS.p2}</Typography>
                  <Typography variant="body1">{ADDRESS.p3}</Typography>
                </div>

                <Enquiry />
              </div>
            </div>
            <div className="col-md-6 contactBg "></div>
          </Row>
        </div>
      </Container>
      <div className="d-none d-md-block d-lg-none contactBgMobile p-5 shadow-lg">
        <div className="d-flex justify-content-center">
          <div className="contactText text-center">
            <Typography
              className="mb-3"
              variant="h3"
              color="white"
              fontWeight={100}
            >
              CONTACT US
            </Typography>

            <div>
              <Typography className="mb-3" variant="h4" color="white">
                {ADDRESS.title}
                <span className="ms-2" style={{ color: pink[500] }}>
                  <i class="fas fa-map-marker-alt    "></i>
                </span>
              </Typography>
              <Typography color="white" variant="body1">
                {ADDRESS.p1}
              </Typography>
              <Typography color="white" variant="body1">
                {ADDRESS.p2}
              </Typography>
              <Typography color="white" variant="body1">
                {ADDRESS.p3}
              </Typography>
            </div>

            <Enquiry />
          </div>
        </div>
      </div>
      <div className="d-sm-block d-md-none contactBgMobile p-5 shadow-lg">
        <div className="contactText">
          <Typography className="mb-3" variant="h5" color="white">
            CONTACT US
          </Typography>

          <div>
            <Typography className="mb-3" variant="h6" color="white">
              {ADDRESS.title}
              <span className="ms-2" style={{ color: pink[500] }}>
                <i class="fas fa-map-marker-alt    "></i>
              </span>
            </Typography>
            <Typography color="white" variant="body1">
              {ADDRESS.p1}
            </Typography>
            <Typography color="white" variant="body1">
              {ADDRESS.p2}
            </Typography>
            <Typography color="white" variant="body1">
              {ADDRESS.p3}
            </Typography>
          </div>

          <Enquiry />
        </div>
      </div>
    </div>
  );
}
