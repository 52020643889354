import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import { Assignment, Person, Email, Phone } from "@mui/icons-material";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import emailImage from "../assets/images/emailImage.jpg";
import { blue } from "@mui/material/colors";

export default function Enquiry() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const defaultData = {
    adminName: "",
    clientName: "",
    email: "",
    tel: "",
    enquiry: "",
  };
  const [formData, setFormData] = useState(defaultData);
  const form = useRef();
  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_ID,
        process.env.ENQUIRY_TEMPLATE,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "success",
          text: "Thank you for contacting us. We will respond shortly.",
          showConfirmButton: false,
          timer: 2000,
        });
        handleClose();
        setFormData(defaultData);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="mt-2">
        <Button
          variant="contained"
          color="error"
          endIcon={<Assignment />}
          onClick={handleShow}
        >
          {" "}
          Make product enquiry
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Typography variant="h6" sx={{ color: blue[600] }}>
            Make an enquiry about our products
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">
            <div>
              <form ref={form} onSubmit={sendEmail}>
                <FormControl className="mb-3">
                  <InputLabel htmlFor="clientName">Name</InputLabel>
                  <OutlinedInput
                    id="clientName"
                    type="text"
                    name="clientName"
                    required
                    value={formData.clientName}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment>
                        <Person color="primary" />
                      </InputAdornment>
                    }
                    label="Name"
                    sx={{ width: 300 }}
                  />
                </FormControl>
                <FormControl className="mb-3">
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment>
                        <Email color="primary" />
                      </InputAdornment>
                    }
                    label="Email"
                    sx={{ width: 300 }}
                  />
                </FormControl>
                <FormControl className="mb-3">
                  <InputLabel htmlFor="tel">Phone Number</InputLabel>
                  <OutlinedInput
                    id="tel"
                    type="tel"
                    name="tel"
                    required
                    value={formData.tel}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment>
                        <Phone color="primary" />
                      </InputAdornment>
                    }
                    label="Phone Number"
                    sx={{ width: 300 }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    label="Enquiry"
                    type="text"
                    required
                    name="enquiry"
                    value={formData.enquiry}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    sx={{ width: 300 }}
                  />
                </FormControl>
                <div className="mt-3">
                  <Button
                    color="info"
                    variant="contained"
                    type="submit"
                    className="me-2"
                  >
                    Send Email
                    <span className="ms-2">
                      <i class="fas fa-paper-plane    "></i>
                    </span>
                  </Button>
                  {loading ? (
                    <Spinner animation="border" size="sm" variant="info" />
                  ) : null}
                </div>
              </form>
              <div className="mt-3">
                <img src={emailImage} className="img-fluid" alt="email" />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="error" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
