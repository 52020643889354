import { Typography } from "@mui/material";

import React from "react";
import { Container } from "react-bootstrap";

import { green } from "@mui/material/colors";

import "./MeetTheCeo.css";
import { TheTeam } from "../../services/labels";
import AvatarComponents from "./AvatarComponents";

export default function MeetTheCeo() {
  return (
    <div className="mt-5">
      <Container>
        <div className="p-4">
          <Typography
            variant="h4"
            textAlign={"center"}
            color={green[500]}
            fontWeight="100"
          >
            THE TEAM
          </Typography>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          {TheTeam.map((member, i) => (
            <AvatarComponents
              avatar={member.avatar}
              title={member.title}
              role={member.role}
              index={i}
            />
          ))}
        </div>
      </Container>
    </div>
  );
}
