import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { categories } from "./imageRolls";
import ajibade from "../assets/images/ajibade2.jpeg";
import kolade from "../assets/images/kolade.jpeg";
import busayo from "../assets/images/busayo.jpeg";
import kehinde from "../assets/images/kehinde.jpeg";
import ayoola from "../assets/images/ayoola.jpeg";
import Oliver from "../assets/images/Oliver.png";

export const WelcomePageText = {
  title: "SARNIAROCK INTERNATIONAL LTD",
  subHeading: "For Safety & Security",
  subtitle: "Perimeter Protection Sytems, Doors & Loading Technology.",
  subtitle2: "We supply and install products in Nigeria and across Africa",
};

export const ProductLabels = categories;

export const FooterLabels = [
  { label: "Home", path: "/" },
  { label: "About Us", path: "/aboutUs" },
  { label: "The Team", path: "/theTeam" },
  { label: "Contact Us", path: "/contactUs" },
];

export const NavLabels = [
  { label: "Home", path: "/" },
  { label: "Products", path: "/products" },
  { label: "About Us", path: "/aboutUs" },
  { label: "The Team", path: "/theTeam" },
  { label: "Brochure", path: "/brochure" },
  { label: "News and Updates", path: "/newsAndUpdates" },
  { label: "Hörmann", path: "https://www.hoermann.com/en/", target: "_blank" },
];
export const SocialMediaHandles = [
  { icon: <Twitter />, url: "https://twitter.com/sarniarockint" },
  { icon: <Facebook />, url: "https://www.facebook.com/sarniarockintl" },
  { icon: <Instagram />, url: "https://www.instagram.com/sarniarock_int" },
];

export const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const DistributorLabel =
  "Is an official Hörmann distributor and is granted the right to sell the whole range of Hörmann products and carry out fitting and service on behalf of Hörmann.";

export const ABOUTUS = {
  p1: "SarniaRock International is an official distributor of Hörmann products in West Africa.",
  p2: "The Hörmann Group is Europe's leading manufacturer of high quality doors, hinged doors, frames, industrial rolling shutters, loading bay technology and perimeter protection system. It has produced and delivered more than 20 million doors worldwide.",
  p3: "SarniaRock International is poised to give support to industrial and residential development in Africa. With great security and safety enhancement products, SarniaRock International supplies and installs these products with ease and expertise.",
  p4: "With its head office in Lagos, SarniaRock International will expand its location and offices throughout West Africa.",
};

export const TheTeam = [
  // {
  //   avatar: ayoola,
  //   title: "Amb. Ayoola Olukanni",
  //   role: "Chairman & Advisor",
  //   paragraphs: [],
  // },
  {
    avatar: ajibade,
    title: "Ajibade Yusuf",
    role: "C.E.O",
    paragraphs: [
      "is a dual citizen of the United Kingdom and Nigeria. He is a seasoned business professional with a first degree in Accounting and Finance from the prestigious Kingston University, Surrey. He also possesses an Executive MBA from Manchester Business School, England.",
      "He is a fellow certified chartered accountant with over two and half decades of experience in investment funds and the banking industry. ",
      "In 2007, he was appointed as the Vice-president of the Belgium-Luxembourg-Nigeria Chamber of Commerce. With his knowledge and wealth of experience, he is the founder and senior consultant of Gradient Consulting Africa, with offices in both London and Luxembourg.",
      "In 2015, he became the Director of Finance & Regulation, Financial Planning and Reporting at RBC Investor and Treasury Services Bank in Luxembourg. ",
      "As an entrepreneur with focus on the development of Africa, in 2022, he formed SarniaRock International to collaborate with Hörmann Group to supplier commercial & industrial equipment to West Africa.   SarniaRock is the official distributor and approved installer of Hörmann products in Nigeria",
      "He is currently a permanent resident of the Grand Duchy of Luxembourg. When he is not doing business or advising the senior banking executives, Ajibade enjoy reading, travel and he is passionate about the future of Africa.",
    ],
  },
  // {
  //   avatar: Oliver,
  //   title: "ARC. TONYE OLIVER BRAIDE, ppnia, fnia",
  //   role: "Product Technical Adviser",
  //   paragraphs: [
  //     "Architect Braide graduated with a First Class in Architecture from Ahmadu Bello University, Zaria and also obtained a Master of Science with Distinction from the same University.  He is a Fellow of the Nigerian Institute of Architects, and a Registered Architect with the Architects Registration Council of Nigeria. From 2015 to 2017, Architect Braide, served the President of Nigerian Institute of Architects.",
  //     "Over the past 37 years, Arc. Braide has worked in various public sector and private business concerns providing services in Architectural Education, Design Consultancy, Construction Management and Project Finance Procurement. Arc. Braide has been involved in sourcing development financing for projects covering Hospitals, Secondary Schools, Hostel Projects, Hotels, Theme Parks, Housing, Smart City Development and Marine Fabrication Development.",
  //     "Arc. Braide’s company, Oliver Braide & Associates Registered Architects AFR-16 provides Architectural Management, Engineering Procurement and Construction Management consultancy. A second company, provides turn-key Development Consultancy services which start from pre-inception studies to design, finance procurement and management all through the project execution to funds amortization",
  //     "Arc. Braide provides additional professional services through a group of companies acting individually or jointly in partnership with selected expatriate best in class professionals engaged solely to execute the business in focus to world class standards.",
  //     "Arc. Braide is married, has one daughter and spends his spare time visiting rural markets to appreciate the essence of rural life, collecting arts and crafts, designing furniture and reading books on astronomy, world history and philosophy and pondering on the essence of this life. ",
  //     "He has travelled to several countries which include:",
  //     "Canada, United States of America, Trinidad & Tobago, Ireland, Britain, Belgium, Netherlands, France, Spain, Portugal, Germany, Austria, Italy, China, India, Japan, South Korea, South Africa, Namibia, Rwanda, Tanzania, Kenya, Uganda, Zambia, Algeria, Ethiopia, Egypt, Dubai, Qatar, Ghana, Cameroun, Sierra Leone.",
  //   ],
  // },
  {
    avatar: busayo,
    title: "BUSAYO OLOWOOKERE",
    role: "Media & Communication Lead",
    paragraphs: [
      "Busayo Olowookere is a media and event specialist and a multi-media broadcaster with over five years of practical experience in the media sector. He has a unique talent for social media management and content creation. He has successfully revamped several organizations' social media pages through various campaigns.",
      "He is a gifted TV and radio presenter with a golden baritone voice. He has presented, produced, and directed shows on radio and TV, which include Jordan 93.9 FM. Abuja, Human Rights Radio and Television 101.1FM Abuja, Jay FM, 101.9FM Jos, Tin City FM Jos, Patris Radio Jos, Nigerian Television Authority (NTA) Jos, Spotlight TV Jos, Property Doctor Show, Women Empowerment Show, NSITF Today Show, Hangout with Busayo Show. He is a gifted master of ceremony and an astute, flawless talent manager.",
      "He has served as an online instructor for the E-WASH project by USAID. He is a communication development specialist, knowledge and development manager, with great strength in public speaking.",
      "Busayo has designed and managed several media and public sensitization campaigns for NGOs, such as the Rule of Law and Empowerment Initiative, also known as Partners West Africa Nigeria. He has facilitated training on Social and Behavior Change Communication (SBCC) for Plan International Nigeria, Social Media Management for NGOs, and lots more. He is also a guru at facilitating media coverage for events.",
      "He is currently the Head, Media and Communications at SarniaRock International where he is responsible for internal and external communication, media, public relations,  brand visibility and growth.",
      "Busayo Olowookere is passionate about leadership and human development with a strong media connection in all 36 states of the Federation, including the FCT.",
    ],
  },
  {
    avatar: kolade,
    title: "Kolade Aladeojebi",
    role: "Head of Engineering Operations",
    paragraphs: [
      "is a certified project manager and a member of the Council for the Regulation of Engineering in Nigeria (COREN). He is presently the Head of Engineering Operations at SarniaRock International and the Chief Creative Officer at Jibalade and Partners Consulting Engineers.",
      "In 2008, he was appointed the Project Manager/Construction Head at Anchor Telecoms, where he planned, directed, coordinated, and budgeted a wide variety of construction projects, ranging from residential, commercial, and industrial structures.",
      "In 2011, he became the Managing Partner at Jibade and Partners Consulting Engineers, where he majorly functioned in strategic sales, project management and delivery, as well as strategic consulting.",
      "Kolade joined New Era Energy a British Firm in 2012 as an Operations Manager. The firm has its operations in Northern Nigeria offices in Abuja, Jigawa and Kano. He was saddled with the responsibilities of planning, developing, and coordinating engineering projects and compliance with best engineering practices.",
      "He specializes in project management, cost containment, budget control, quality delivery, conflict resolution, and time management.",
      "He is horned with leadership, team building, business management skills, and a great level of construction and project delivery.",
    ],
  },
  {
    avatar: kehinde,
    title: "KEHINDE OYEDEJI",
    role: "Head, Sales & Marketing",
    paragraphs: [
      "He obtained his first degree in the year 2002 as a Business Administration graduate from Lagos State University. He attended Metropolitan School of Business & Management (UK), and also member of Institute of Risk Management UK.  Kehinde proceeded to the Daystar Leadership Academy in 2007 to gain insights into the world of leadership",
      "In 2008, he became the Infrastructure Marketing Officer at Pay Tech, a payroll and HCM consulting firm. He was instrumental in accelerating solutions to market, partnering with key members of the ecosystem and ensuring effective communication to the sales force and customers about the benefits of the products offered.",
      "He was appointed in 2010 as an Account Manager at LogicPoint Ltd. where he acted as the primary contact for customers, built and maintained strong, long-lasting customer relationships.",
      "In 2012, he was appointed the Account Manager in Westcon Africa, Nigeria, where he provided direction to staff and was responsible for implementing workflow procedures, monitoring spending patterns and budget.",
      "He became the National Sales Manager in 2015 at Halogen and proceeded to becoming Head of Business, Electronics Security at Halogen Security Company Limited. ",
      "Due to his wealth of experience in the business, sales and human development, he was appointed as the General Manager Business Development Pavilion Technology Limited in 2018.",
      "Kehinde Oyedeji has in recent times achieved expertise in Key Business Account Management, sustaining and on boarding valuable partners to the various globally known OEMs i.e, Juniper, Avaya, Motorola, Rukus, Sunus, Commscope Zebra, Plantronics.",
      "He has generated in excess of $35,000,000 within public and private sector accounts, both in Nigeria and Ghana",
      "He presently works as Head, Sales & Marketing at SarniaRock International and also as Director at Bryan & Beckley International",
    ],
  },
];

export const ADDRESS = {
  title: "Nigeria Office",
  p1: "No 1a Arowolo Adeyemo Street",
  p2: "Millennium Estate ",
  p3: "Gbagada, Lagos",
};

export const PRODUCTDESCRIPTION = [
  {
    title: "DURABILITY",
    texts: [
      "When faced with the obstacles of routine operation during their course of use, our products are designed to remain functioning without requiring significant maintenance or repair. Our products are capable of adjusting to any type of technical, technological, or design advancement.",
    ],
  },
  {
    title: "LONGEVITY",
    texts: [
      "Hörmann products are made from the finest materials and finishing touches that emanate from Europe’s best designers and engineers. These products are made to serve you for longer term without the need to replace them.",
    ],
  },
  {
    title: "QUALITY",
    texts: [
      "We pride ourselves in providing the best quality products that has withstand rigorous testing and complies with international quality standards.",
    ],
  },
  {
    title: "ENVIRONMENTAL FRIENDLY",
    texts: [
      "Our products have received confirmation of its sustainability of all its multi-function doors through an Environmental Product Declaration (EPD) in accordance with ISO 140425 from the Institut für Fenstertechnik in Rosenheim (Institute for Window Technology).",
      "The inspection was based on the Product Category Rules (PCR) Doors and Gates ift Rosenheim GmbH, issue PCR-TT-0.1.Environmentally friendly production was confirmed by a life cycle analysis in accordance with DIN EN 14040/14044 for all doors",
      "Depending on your preference on any of our products, each of our products is built to comply with United Kingdom, USA and International standards",
    ],
  },
];

export const BrochureImages = [
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/84299_British-Standard_EN.jpg?v=1613569159",
    title: "Fire-Rated Steel Doors to British Standard",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/84299_British-Standard_EN.pdf?v=1613569146",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/84477_Hofschiebetore_EN.jpg?v=1529046004",
    title: "Sliding Gates",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/84477_Hofschiebetore_EN.pdf?v=1529045440",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/84555_Rolltore-Rollgitter_EN.jpg?v=1605173750",
    title: "Rolling Shutters and Rolling Grilles",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/84555_Rolltore-Rollgitter_EN.pdf?v=1605173775",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86465_Industrie-Sectionaltore_EN.jpg?v=1631867858",
    title: "Industrial Sectional Doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86465_Industrie-Sectionaltore_EN.pdf?v=1631800552",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/84894_Schnelllauftore_EN.jpg?v=1634725161",
    title: "High-speed doors",
    downloadUrl:
      " https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/84894_Schnelllauftore_EN.pdf?v=1634725136",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85013_Stahlzargen_EN.jpg?v=1607591452",
    title: "Steel frames",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/85013_Stahlzargen_EN.pdf?v=1607591667",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85115_Stahltueren_EN.jpg?v=1603439662",
    title: "Steel doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/85115_Stahltueren_EN.pdf?v=1603439677",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85375_Stahl-Objekttueren-OD_EN.jpg?v=1612341086",
    title: "Steel construction project doors OD",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/85375_Stahl-Objekttueren-OD_EN.pdf?v=1612341710",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85728_Double_Action_Doors_and_Strip_Curtains_EN.jpg?v=1532344790",
    title: "Double-Action Doors and Strip Curtains",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85728_Double_Action_Doors_and_Strip_Curtains_EN.jpg?v=1532344790",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85815-Sammelgaragentore-EN.jpg?v=1532072831",
    title: "Door Sytems for Collective Garages",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/85815-Sammelgaragentore-EN.pdf?v=1560236950",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85828_Thermo65-Thermo46_UK.jpg?v=1629894601",
    title: "Thermo65/Thermo46 Entrance Doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85828_Thermo65-Thermo46_UK.jpg?v=1629894601",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85900_Garagentore-RollMatic_ENonlyUK.jpg?v=1607514424",
    title: "Garage doors RollMatic",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/85900_Garagentore-RollMatic_ENonlyUK.pdf?v=1607514409",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85945_Garagen-Einfahrtstor-Antriebe_EN.jpg?v=1610536542",
    title: "Garage door and entrance gate operators",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/85945_Garagen-Einfahrtstor-Antriebe_EN.pdf?v=1610536637",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86278_Verladetechnik_EN.jpg?v=1562150948",
    title: "Loading Technology",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86278_Verladetechnik_EN.pdf?v=1562745125",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86301-Berry-Tore-Kurzversion-EN.jpg?v=1631867412",
    title: "UP-AND-OVER Garage Doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86301-Berry-Tore-Kurzversion-EN.pdf?v=1631527182",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86466_Rollgitter_RollMatic_EN.jpg?v=1508489772",
    title: "RollMatic rolling grille",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86466_Rollgitter_RollMatic_EN.pdf?v=1508489552",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86469_Sporthallentore_EN.jpg?v=1621598114",
    title: "Sports hall doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86469_Sporthallentore_EN.pdf?v=1621598101",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86892_Aluminium-Haustueren_EN.jpg?v=1628856106",
    title: "Aluminium Entrance Doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86892_Aluminium-Haustueren_EN.pdf?v=1628856075",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86873-PortaMatic-EN.jpg?v=1524839525",
    title: "Door Operator PortaMatic",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86873-PortaMatic-EN.pdf?v=1524735974",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/85184_Garagen-Sektionaltor_onlyUK.jpg?v=1650613976",
    title: "Sectional Garage Doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/85184_Garagen-Sektionaltor_onlyUK.pdf?v=1650614006",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86972-Doppel-_und_Sammelgaragentore-EN.jpg?v=1539154654",
    title: "Double and Collective Garage Doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86972-Doppel-_und_Sammelgaragentore-EN.pdf?v=1539155827",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86310_Produkte-fuer-den-Wohnungsbau_EN.jpg?v=1610536542",
    title: "Products for residential construction",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86310_Produkte-fuer-den-Wohnungsbau_EN.pdf?v=1610536635",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/87131_ZKS-Poller_EN.jpg?v=1619691900",
    title: "Perimeter Protection Systems",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/87131_ZKS-Poller_EN.pdf?v=1621513436",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86734_Zimmertueren_EN.jpg?v=1646813324",
    title: "Residential Internal Doors",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86734_Zimmertueren_EN.pdf?v=1646813305",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/87175-SmartKey_UK.jpg?v=1531213239",
    title: "Radio Door Lock Operator SmartKey",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/87175-SmartKey_UK.pdf?v=1531213259",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86038_Stahl-Edelstahl-Schiebetore-OD_EN.jpg?v=1607587070",
    title: "Steel and Stainless Steel Sliding Doors OD",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86038_Stahl-Edelstahl-Schiebetore-OD_EN.pdf?v=1607587096",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/Tuerbeschlaege_in_Design_Broschuere_Hoermann_EN.jpg?v=1537424914",
    title: "Door Fittings",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/Tuerbeschlaege_in_Design_Broschuere_Hoermann_EN.pdf?v=1537424490",
  },
  {
    image:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/img/86463_Stahl-Edelstahltueren-STS-STU_EN.jpg?v=1603355548",
    title: "Steel and Stainless steel construction project doors STS/STU",
    downloadUrl:
      "https://cdn.hoermann-cloud.de/fileadmin/_country/kataloge/pdf/86463_Stahl-Edelstahltueren-STS-STU_EN.pdf?v=1603355570",
  },
];

export const theme = { deep: "#3b6120", normal: "#558b2f", light: "#77a258" };
export const secondaryTheme = {
  deep: "#1c54b2",
  normal: "#2979ff",
  light: "#5393ff",
};
