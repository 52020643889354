import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import MainRoutes from "./routes";
import Footer from "./components/Footer";
import NavigationBar from "./components/NavigationBar";
import Page from "react-page-loading";
import { green } from "@mui/material/colors";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ReactGA from "react-ga";

function App() {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_CODE);
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
      <Page loader={"bar"} color={green[500]} size={4}>
        <NavigationBar />
        <div className="mt-5">
          <MainRoutes />
        </div>
        <Footer></Footer>
      </Page>
    </div>
  );
}

export default App;
