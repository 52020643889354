import {
  Email,
  Landscape,
  Visibility,
  VisibilityOff,
  Login,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import delivery from "../../assets/images/delivery.jpg";
import { httpService } from "../../services/services";

export default function SignInPage() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({});

  const handleShow = () => {
    setShow(!show);
  };
  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };
  const SignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const path = "login";
      const res = await httpService.post(path, account);

      localStorage.setItem("token", res.data.accessToken);
      localStorage.setItem("userData", JSON.stringify(res.data.user));
      window.location.assign("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-5">
        <Container>
          <Row className="p-3 ">
            <div className="col-lg-6">
              <img
                src={delivery}
                alt="delivery"
                className="img-fluid img-responsive rounded-3 shadow-lg"
              />
            </div>
            <div className="col-lg-6 shadow-lg rounded-3">
              <div className="p-3">
                <Landscape htmlColor="green" />
                <Typography
                  variant="h5"
                  fontWeight={100}
                  className="text-uppercase"
                >
                  Sign into your Sarnia Account
                </Typography>
                <form onSubmit={SignIn}>
                  <div>
                    <FormControl className="mt-3">
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        color="success"
                        endAdornment={
                          <InputAdornment position="end">
                            <Email color="success" />
                          </InputAdornment>
                        }
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={account.email}
                      ></OutlinedInput>
                    </FormControl>
                  </div>
                  <div className="mt-3">
                    <FormControl>
                      <InputLabel htmlFor="email">Password</InputLabel>
                      <OutlinedInput
                        color="success"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleShow}>
                              {!show ? (
                                <Visibility color="success" />
                              ) : (
                                <VisibilityOff color="error" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        name="password"
                        onChange={handleChange}
                        value={account.password}
                        type={show ? "text" : "password"}
                      ></OutlinedInput>
                    </FormControl>
                  </div>
                  <div className="mt-3">
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      endIcon={<Login />}
                    >
                      Login{" "}
                    </Button>
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="success"
                        className="ms-2"
                        size="sm"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}
