import React from "react";
import { Row } from "react-bootstrap";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";

import perimeterFences from "../../assets/images/img5.jpg";
import doors from "../../assets/carouselImages/img5.jpg";
import loadingTechnology from "../../assets/images/loadTechnology.jpg";
import bollard from "../../assets/images/bollard.jpg";
import { categories } from "../../services/imageRolls";

export default function PictureCards() {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <Row>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 2, md: 4 }}
          >
            <Card sx={{ boxShadow: 3, maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia component="img" height="200" image={doors} />
                <CardContent>
                  <Typography className="text-center">
                    {categories[0].name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card sx={{ boxShadow: 3, width: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={loadingTechnology}
                />
                <CardContent>
                  <Typography className="text-center">
                    {categories[2].name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card sx={{ boxShadow: 3, maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia component="img" height="200" image={bollard} />
                <CardContent>
                  <Typography className="text-center">
                    {categories[1].name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Stack>
        </Row>
      </div>
    </div>
  );
}
