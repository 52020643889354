export const news1 = [
  {
    headline:
      "HÖRMANN SET TO GIVE BUILDERS, HOME OWNERS NEW DEAL IN QUALITY DOORS IN NIGERIA",
    slug: "HÖRMANN SET TO GIVE BUILDERS, HOME OWNERS NEW DEAL IN QUALITY DOORS IN NIGERIA".toLowerCase(),
    paragraphs1: [
      "A leading European door manufacturer, Hörmann is poised to raise the bar of customer satisfaction in the Nigerian door market by giving builders time-proven attention to details in the quality of materials used in the manufacturing of door processes",
      "Addressing the stakeholders recently at the introduction of the German door brand at the Radisson Blu Anchorage Victoria Islands, Lagos.   The Managing Director and Founder of SarniaRock International Limited, the official distributor of Hörmann products in Nigeria and West Africa, Mr. Ajibade Yusuf said it is a new day for customers in Nigeria because the brand would provide the quality to appease to Nigerians craving for superior and durable product.",
      "He told the audience that SarniaRock will provide an end-to-end solution in procurement, foreign exchange, installation as well as after-sales service to our customers.",
      "He noted that Hörmann doors, which started production in 1935 in Germany stand out from the crowd because the manufacturer produces based on requirements and precession that delivers personalized emotional connection for clients satisfaction far above what competition offers in the market",
      "Mr. Ajibade said “we want to be part of our client’s story by working with them from idea creation or conceptualization of the building, that is, at the design and drawing stage.  This allows us to understand the needs of our customers very well as we can provide technical support from the onset.  SarniaRock have highly trained engineers on the ground to ensure measurement is accurate and product is installed correctly”.",
    ],
    paragraphs2: [
      "Our team in Lagos is well position to travel across the country to provide one-to-one / face-to-face rapport with our customers.  Ajibade revealed that after-sales service is another added value the clients would enjoy from Hörmann as there is a dedicated support team and highly trained technicians and engineers to assist with installation and maintenance.",
      "The SarniaRock boss said Hörmann products, the market leader in Europe with a range of products have varied guarantee periods of 1 to 10 years which speaks volumes about the quality and the confidence we have in our brand which has been in existence for over 80 years.",
      "The press briefing was concluded with a zoom meeting between the stakeholders in the building industry in Nigeria and representatives of Hörmann in UAE who gave insights into the opportunities the former stands to gain by using the Hörmann brand and varieties of doors available for residential, commercial, and industrial doors including perimeter protection system.",
      "Mr. Ajibade can be contacted ajibadeyusuf@sarniarockinternational.com",
    ],
  },
];
