import {
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import engineers from "../../assets/images/engineers.jpg";
import { blue, grey } from "@mui/material/colors";
import { Person, Email, Phone } from "@mui/icons-material";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import logoFull from "../../assets/Logo/logoFull.png";

export default function JoinUsPage() {
  const form = useRef();
  const defaultData = {
    adminName: "",
    clientName: "",
    email: "",
    tel: "",
    enquiry: "",
  };
  const [formData, setFormData] = useState(defaultData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    // Swal.fire({
    //   icon: "info",
    //   title: "Service temporarily availble",
    //   text: "Sorry we are unable to process application at this time. We are working on rectifying the problem",
    // });
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_ID,
        process.env.REACT_APP_APPLICATION_TEMPLATE,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "success",
          text: "Thank you for contacting us. We will respond shortly.",
          showConfirmButton: false,
          timer: 2000,
        });
        setFormData(defaultData);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Container className="shadow-lg mt-3">
        <Row>
          <div
            className="col-md-6 p-5 d-flex align-items-center  "
            style={{ backgroundColor: grey[100] }}
          >
            <div>
              <Typography className="mb-3" color={blue[500]} variant="h3">
                JOIN US
              </Typography>
              <Typography className="mb-3">
                SarniaRock International is recruiting. Join our exciting team
                today if you are an experienced civil or mechanical engineer,
                sales and marketing professional.
              </Typography>
              {/* <Typography>
                You can be part of that team today if you have the qualification
                and technical know-how
              </Typography> */}

              <div className="mt-3">
                <form ref={form} onSubmit={sendEmail}>
                  <FormControl className="mb-3">
                    <InputLabel htmlFor="clientName">Name</InputLabel>
                    <OutlinedInput
                      id="clientName"
                      type="text"
                      name="clientName"
                      required
                      value={formData.clientName}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment>
                          <Person color="primary" />
                        </InputAdornment>
                      }
                      label="Name"
                      sx={{ width: 300 }}
                    />
                  </FormControl>
                  <FormControl className="mb-3">
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                      id="email"
                      type="email"
                      required
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment>
                          <Email color="primary" />
                        </InputAdornment>
                      }
                      label="Email"
                      sx={{ width: 300 }}
                    />
                  </FormControl>
                  <FormControl className="mb-3">
                    <InputLabel htmlFor="tel">Phone Number</InputLabel>
                    <OutlinedInput
                      id="tel"
                      type="tel"
                      name="tel"
                      required
                      value={formData.tel}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment>
                          <Phone color="primary" />
                        </InputAdornment>
                      }
                      label="Phone Number"
                      sx={{ width: 300 }}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.role}
                      name="role"
                      onChange={handleChange}
                      sx={{ width: 300 }}
                      label="Role"
                    >
                      <MenuItem value="Civil Engineer" selected>
                        Civil Engineer
                      </MenuItem>
                      <MenuItem value="Mechanical Engineer">
                        Mechanical Engineer
                      </MenuItem>
                      <MenuItem value="Sales Professional">
                        Sales Professional
                      </MenuItem>
                      <MenuItem value="Marketing Professional">
                        Marketing Professional
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className="mt-3">
                    <Button
                      color="info"
                      variant="contained"
                      type="submit"
                      className="me-2"
                    >
                      Send Application
                      <span className="ms-2">
                        <i class="fas fa-paper-plane    "></i>
                      </span>
                    </Button>
                    {loading ? (
                      <Spinner animation="border" size="sm" variant="info" />
                    ) : null}
                  </div>
                </form>
              </div>
              <div className="d-flex justify-content-start mt-4">
                <img
                  src={logoFull}
                  className="img-fluid"
                  alt="logo"
                  width="100"
                  height="50"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 d-flex align-items-center">
            <div>
              <div>
                <img
                  src={engineers}
                  className="img-fluid shadow-lg"
                  alt="sarnia engineers"
                />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}
