import { Upgrade, PhotoCamera, Cancel } from "@mui/icons-material";
import { Button, Typography, Chip, Stack, Avatar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CannotGet, httpService } from "../../services/services";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";

const Input = styled("input")({
  display: "none",
});

export default function SingleProduct() {
  const [product, setProduct] = useState({ price: "", images: [] });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const GetProduct = async () => {
    setLoading(true);
    try {
      const path = `viewProduct/${id}`;
      const res = await httpService.get(path);
      setProduct(res.data.product);
      setLoading(false);
    } catch (error) {
      CannotGet();
      setLoading(false);
    }
  };
  const changeHandler = (event) => {
    const files = [];

    const length = event.target.files.length;
    if (length > 4) {
      Swal.fire({
        icon: "error",
        title: "Maximum number of images exceeded",
        text: "A product can only have a maximum of four images",
      });
    } else {
      for (let index = 0; index < length; index++) {
        files.push(event.target.files[index]);
      }
      setSelectedFiles(files);
    }
  };

  const UploadImages = async () => {
    const formData = new FormData();
    selectedFiles.forEach((img, i) => {
      formData.append("image", img, img.name);
    });

    try {
      setLoading(true);
      const path = `uploadProductImages/${product._id}`;
      const res = await httpService.patch(path, formData);
      if (res) {
        setLoading(false);
        setSelectedFiles([]);
        window.location.reload();
      }
    } catch (error) {
      CannotGet();
      setLoading(false);
    }
  };

  useEffect(() => {
    GetProduct();
  }, []);
  return (
    <div>
      <Container>
        <div className="shadow-lg p-3 mt-3 rounded-3">
          {loading ? <Spinner animation="border" /> : ""}
          <div className="d-flex justify-content-end">
            <Button variant="contained" color="secondary" endIcon={<Upgrade />}>
              Update Product
            </Button>
          </div>
          <div className="row">
            <div className="col-md-6 border-end">
              <Typography className="mb-4">PRODUCT</Typography>
              <Typography variant="h6">Name: {product.title}</Typography>
              <Typography variant="h6" className="mt-3">
                Price: ₦{product.price.toLocaleString()}
              </Typography>
              <Typography variant="h6" className="mt-3">
                Quantity: {product.quantity}
              </Typography>
              <Chip
                label={product.available ? "In stock" : "Out of stock"}
                color={product.available ? "success" : "error"}
              />
              <hr />
              <Typography variant="h6">Images</Typography>
              <div>
                {product.images.length > 0 ? (
                  <>
                    <Stack direction="row" spacing={2}>
                      {product.images.map((image, i) => (
                        <Avatar alt={i} src={image} />
                      ))}
                    </Stack>
                  </>
                ) : (
                  <>
                    {/* <Button endIcon={<UploadFile />}>Upload Images</Button> */}
                    <label htmlFor="icon-button-file">
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        onChange={changeHandler}
                        multiple
                      />
                      {/* <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera />
                      </IconButton> */}
                      <Button
                        color="info"
                        endIcon={<PhotoCamera />}
                        aria-label="upload picture"
                        component="span"
                      >
                        Upload product images
                      </Button>
                    </label>
                    {selectedFiles.length > 0 ? (
                      <div>
                        <div className="d-flex flex-wrap">
                          {selectedFiles.map((image, i) => (
                            <div key={i} className="mb-2 me-2">
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`img${i}`}
                                width={200}
                                height={200}
                              />
                            </div>
                          ))}
                        </div>
                        <Button
                          color="error"
                          onClick={() => {
                            setSelectedFiles([]);
                          }}
                          endIcon={<Cancel />}
                        >
                          Remove Images
                        </Button>
                        <Button onClick={UploadImages} endIcon={<Upgrade />}>
                          Upload Images
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <Typography>Reviews</Typography>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
