import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { Container, Row } from "react-bootstrap";
import img8 from "../assets/images/updates/img8.jpg";
import img4 from "../assets/images/updates/img4.jpg";
import img7 from "../assets/images/updates/img7.jpg";
import img1 from "../assets/images/updates/img1.jpg";
import img10 from "../assets/images/updates/img10.jpg";
import img15 from "../assets/images/updates/img15.jpg";
import img14 from "../assets/images/updates/img14.jpg";
import { news1 } from "./newsText";
import { Newspaper } from "@mui/icons-material";

export default function NewsAndUpdates() {
  return (
    <div>
      <Container className="border p-3">
        <div className="mt-3 mb-3">
          <Typography variant="h4" fontWeight={700} gutterBottom>
            News & Updates
          </Typography>
          <hr />
        </div>

        {/* for desktops */}
        <div className="d-none d-lg-block">
          <Row>
            <div className="col-md-7">
              <Card>
                <CardMedia
                  component="img"
                  alt="Ajibade Yusuf Managing Director SarniaRock International Limited"
                  height="400"
                  loading="lazy"
                  image={img8}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    color="GrayText"
                    component="div"
                  >
                    {news1[0].headline}
                  </Typography>
                  <Button
                    startIcon={<Newspaper />}
                    onClick={() => {
                      window.location.assign(
                        `/newsAndUpdates/${news1[0].slug}`
                      );
                    }}
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-5 border-start">
              <div className="mt-3 mb-3">
                <Typography variant="h5">
                  Photo Speaks <i class="fas fa-camera    "></i>{" "}
                </Typography>
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                <div className="col-md-3 me-2 mb-2">
                  <img className="img-fluid" alt="Sarnia Rock" src={img7} />
                </div>
                <div className="col-md-3 me-2 mb-2">
                  <img className="img-fluid" alt="Sarnia Rock" src={img1} />
                </div>
                <div className="col-md-3 me-2 mb-2">
                  {" "}
                  <img className="img-fluid" alt="Sarnia Rock" src={img10} />
                </div>
                <div className="col-md-3 me-2 mb-2">
                  {" "}
                  <img className="img-fluid" alt="Sarnia Rock" src={img15} />
                </div>
                <div className="col-md-3 me-2 mb-2">
                  {" "}
                  <img className="img-fluid" alt="Sarnia Rock" src={img4} />
                </div>
                <div className="col-md-3 me-2 mb-2">
                  {" "}
                  <img className="img-fluid" alt="Sarnia Rock" src={img14} />
                </div>
              </div>
            </div>
          </Row>
        </div>

        {/* for tablets */}
        <div className="d-none d-md-block d-lg-none">
          <Card>
            <CardMedia
              component="img"
              alt="Ajibade Yusuf Managing Director SarniaRock International Limited"
              height="400"
              image={img8}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                color="GrayText"
                component="div"
              >
                {news1[0].headline}
              </Typography>
              <Button
                startIcon={<Newspaper />}
                onClick={() => {
                  window.location.assign(`/newsAndUpdates/${news1[0].slug}`);
                }}
              >
                Read More
              </Button>
            </CardContent>
          </Card>
          <div className="mt-3">
            <div className="mt-3 mb-3">
              <Typography variant="h5">
                Photo Speaks <i class="fas fa-camera    "></i>{" "}
              </Typography>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-md-3 me-2 mb-2">
                <img className="img-fluid" alt="Sarnia Rock" src={img7} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                <img className="img-fluid" alt="Sarnia Rock" src={img1} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img10} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img15} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img4} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img14} />
              </div>
            </div>
          </div>
        </div>

        <div className="d-sm-block d-md-none">
          <Card>
            <CardMedia
              component="img"
              alt="Ajibade Yusuf Managing Director SarniaRock International Limited"
              height="300"
              image={img8}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                color="GrayText"
                component="div"
              >
                {news1[0].headline}
              </Typography>
              <Button
                startIcon={<Newspaper />}
                onClick={() => {
                  window.location.assign(`/newsAndUpdates/${news1[0].slug}`);
                }}
              >
                Read More
              </Button>
            </CardContent>
          </Card>
          <div className="mt-3">
            <div className="mt-3 mb-3">
              <Typography variant="h5">
                Photo Speaks <i class="fas fa-camera    "></i>{" "}
              </Typography>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-md-3 me-2 mb-2">
                <img className="img-fluid" alt="Sarnia Rock" src={img7} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                <img className="img-fluid" alt="Sarnia Rock" src={img1} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img10} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img15} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img4} />
              </div>
              <div className="col-md-3 me-2 mb-2">
                {" "}
                <img className="img-fluid" alt="Sarnia Rock" src={img14} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
