import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { BrochureImages } from "../../services/labels";
import CardComponent from "./CardComponent";
import Enquiry from "../../components/Enquiry";

export default function BrochurePage() {
  const [searchText, setSearchText] = useState("");

  return (
    <div className="mt-5">
      <Container>
        <div>
          <div className="p-4">
            <Typography
              variant="h4"
              textAlign={"center"}
              fontWeight={100}
              color="GrayText"
            >
              BROCHURES
            </Typography>

            <div className="d-flex justify-content-center mt-3">
              <TextField
                label="search product"
                type="search"
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              />
            </div>
            <div className="text-center">
              <Enquiry />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {BrochureImages.filter((e) =>
              e.title.toLowerCase().includes(searchText)
            ).map((img, i) => (
              <CardComponent
                key={i}
                img={img.image}
                title={img.title}
                downloadUrl={img.downloadUrl}
              />
            ))}
          </div>
          <div className="text-center">
            <Enquiry />
          </div>
        </div>
      </Container>
    </div>
  );
}
