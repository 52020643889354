import { Typography } from "@mui/material";
import React from "react";
import { Container, Row } from "react-bootstrap";
import { ABOUTUS } from "../../services/labels";
import "./AboutUs.css";
import badge from "../../assets/Logo/badge.png";
import { green } from "@mui/material/colors";

export default function AboutUs() {
  return (
    <div>
      <Container className="shadow-lg rounded-3">
        <Row>
          <div className="col-md-6 aboutUsBg d-flex align-items-center justify-content-center">
            <img
              src={badge}
              alt="logo"
              className="img-fluid"
              height={200}
              width={200}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center p-4">
            <div>
              <Typography
                variant="h3"
                sx={{ color: green[500] }}
                className="mb-3"
              >
                ABOUT US
              </Typography>
              <Typography className="mb-3">{ABOUTUS.p1}</Typography>
              <Typography className="mb-3">{ABOUTUS.p2}</Typography>
              <Typography className="mb-3">{ABOUTUS.p3}</Typography>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}
