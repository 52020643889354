import { Link, Typography } from "@mui/material";
import React from "react";
import { Row } from "react-bootstrap";
import {
  FooterLabels,
  SocialMediaHandles,
  WelcomePageText,
} from "../services/labels";
import "./Footer.css";
import whiteWatermark from "../assets/Logo/whiteWatermark.png";

export default function Footer() {
  return (
    <div className="footerBg p-3 mt-3">
      <div className="d-none d-md-block">
        <Row>
          <div className="col-md-4 text-center text-white  d-flex align-items-center justify-content-center">
            {FooterLabels.map((label, index) => (
              <Link
                key={index}
                href={label.path}
                className="me-3"
                color="inherit"
              >
                {label.label}
              </Link>
            ))}
          </div>
          <div className="col-md-4 text-center">
            <img
              src={whiteWatermark}
              height="100"
              width="200"
              alt="brand logo"
              className="mb-3"
            />
            <Typography color="white"> {WelcomePageText.title}</Typography>
          </div>
          <div className="col-md-4  text-white d-flex align-items-center justify-content-center">
            {SocialMediaHandles.map((sm, i) => (
              <Link
                key={i}
                href={sm.url}
                target="_blank"
                className="me-3"
                color="inherit"
              >
                {sm.icon}
              </Link>
            ))}
          </div>
        </Row>
      </div>
      <div className="d-block d-md-none">
        <Row>
          <div className="col-md-4 text-center mb-3">
            <img
              src={whiteWatermark}
              height="50"
              width="100"
              alt="brand logo"
              className="mb-3"
            />
            <Typography color="white"> {WelcomePageText.title}</Typography>
          </div>
          <div className="col-md-4 text-center text-white  d-flex align-items-center justify-content-center mb-3">
            {FooterLabels.map((label, index) => (
              <Link
                key={index}
                href={label.path}
                className="me-3"
                color="inherit"
              >
                {label.label}
              </Link>
            ))}
          </div>
          <div className="col-md-4  text-white d-flex align-items-center justify-content-center mb-3">
            {SocialMediaHandles.map((sm, i) => (
              <Link
                key={i}
                href={sm.url}
                target="_blank"
                className="me-3"
                color="inherit"
              >
                {sm.icon}
              </Link>
            ))}
          </div>
        </Row>
      </div>
    </div>
  );
}
