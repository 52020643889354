import React from "react";
import { Spinner } from "react-bootstrap";
import { secondaryTheme, theme } from "../../services/labels";

export default function Loading({ size, show }) {
  return show ? (
    <Spinner
      size={size || "sm"}
      style={{ color: theme.normal }}
      animation="border"
    />
  ) : null;
}

function LargeLoading({ size, show }) {
  return show ? (
    <Spinner style={{ color: secondaryTheme.deep }} animation="border" />
  ) : null;
}

export { LargeLoading };
