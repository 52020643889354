import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { news1 } from "./newsText";
import newsImg1 from "../assets/images/updates/newsImg1.jpg";
import ceo1 from "../assets/images/updates/ceo1.jpg";
import ceo2 from "../assets/images/updates/ceo2.jpg";
import ceo3 from "../assets/images/updates/ceo3.jpg";

export default function NewsView() {
  const { slug } = useParams();
  const [news, setNews] = useState(null);

  useEffect(() => {
    const newsObject = news1.find((c) => c.slug === slug);
    setNews(newsObject);
  }, []);

  return (
    <div>
      <Container className="border p-3">
        {news ? (
          <div className="mt-3 mb-3">
            <Typography variant="h4" fontWeight={700} gutterBottom>
              {news.headline}
            </Typography>
            <hr />
            <div className="mt-3">
              <Row>
                <div className="col-lg-6">
                  <Card>
                    <CardMedia
                      component="img"
                      src={newsImg1}
                      alt="Ajibade Yusuf Managing Director SarniaRock International Limited"
                      height="400"
                      loading="lazy"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Ajibade Yusuf Managing Director SarniaRock International
                        Limited
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-lg-6">
                  {news.paragraphs1.map((c) => (
                    <Typography gutterBottom>{c}</Typography>
                  ))}
                </div>
              </Row>
              <div className="mt-3">
                <div className="d-sm-block d-lg-none">
                  <Row>
                    <div className="col-lg-6 d-flex align-items-center mb-3">
                      <Stack direction="row" spacing={1}>
                        <div className="col-md-3 shadow">
                          <img className="img-fluid" src={ceo1} />
                        </div>
                        <div className="col-md-3 shadow">
                          <img className="img-fluid" src={ceo2} />
                        </div>
                        <div className="col-md-3 shadow">
                          <img className="img-fluid" src={ceo1} />
                        </div>
                      </Stack>
                    </div>
                    <div className="col-lg-6">
                      {news.paragraphs2.map((c) => (
                        <Typography gutterBottom>{c}</Typography>
                      ))}
                    </div>
                  </Row>
                </div>

                <div className="d-none d-lg-block">
                  <Row>
                    <div className="col-lg-6">
                      {news.paragraphs2.map((c) => (
                        <Typography gutterBottom>{c}</Typography>
                      ))}
                    </div>
                    <div className="col-lg-6 d-flex align-items-center mb-3">
                      <Stack direction="row" spacing={1}>
                        <div className="col-md-3 shadow">
                          <img className="img-fluid" src={ceo1} />
                        </div>
                        <div className="col-md-3 shadow">
                          <img className="img-fluid" src={ceo2} />
                        </div>
                        <div className="col-md-3 shadow">
                          <img className="img-fluid" src={ceo1} />
                        </div>
                      </Stack>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Container>
    </div>
  );
}
