import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Loading, { LargeLoading } from "../assets/aesthetics/Loading";
import { secondaryTheme } from "../services/labels";
import { httpService } from "../services/services";

export default function RegisteredPersons() {
  const [persons, setPersons] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetRegistered = async () => {
    try {
      setLoading(true);
      const path = "viewRegistered";
      const res = await httpService.get(path);

      if (res) {
        setPersons(res.data.persons);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const columns = [
    { name: "Name", selector: (row) => row.name },
    { name: "Organisation", selector: (row) => row.organisation },
    { name: "Email", selector: (row) => row.email },
    { name: "Phone Number", selector: (row) => row.phoneNumber },
  ];
  useEffect(() => {
    GetRegistered();
  }, []);
  return (
    <div>
      <div className="p-3"></div>
      <Container>
        <div className="d-flex justify-content-between">
          <div>
            <Typography
              fontFamily={"Lato"}
              fontWeight={900}
              color={secondaryTheme.deep}
            >
              REGISTERED PERSONS
            </Typography>
          </div>
          <div className="d-flex align-items-center">
            <LargeLoading show={loading} />
          </div>
        </div>
        <div className="mt-2">
          <DataTable columns={columns} data={persons} />
        </div>
      </Container>
    </div>
  );
}
