import AboutUs from "../pages/About/AboutUs";
import Dashboard from "../pages/Admin/Dashboard";
import SignInPage from "../pages/Auth/SignInPage";
import BrochurePage from "../pages/Brochure/BrochurePage";
import ContactUs from "../pages/Contact/ContactUs";
import JoinUsPage from "../pages/JoinUs/JoinUsPage";
import NewsAndUpdates from "../pages/NewsAndUpdates";
import NewsView from "../pages/NewsView";
import NotFound from "../pages/NotFound";
import OrdersPage from "../pages/Orders/OrdersPage";
import PreProducts from "../pages/Products/PreProducts";
import ProductCategoryView from "../pages/Products/ProductCategoryView";

import ProductsList from "../pages/Products/ProductsList";
import SingleProduct from "../pages/Products/SingleProduct";
import RegisteredPersons from "../pages/RegisteredPersons";
import IndidualBio from "../pages/TheCEO/IndidualBio";
import MeetTheCeo from "../pages/TheCEO/MeetTheCeo";
import WelcomePage from "../pages/Welcome/WelcomePage";

const publicRoutes = [
  { path: "/", component: WelcomePage },
  { path: "/signIn", component: SignInPage },
  { path: "/shopProducts", component: OrdersPage },
  { path: "/aboutus", component: AboutUs },
  { path: "/theTeam", component: MeetTheCeo },
  { path: "/theTeam/:id", component: IndidualBio },
  { path: "/contactus", component: ContactUs },
  { path: "/brochure", component: BrochurePage },
  { path: "/products", component: PreProducts },
  { path: "/products/:id", component: ProductCategoryView },
  { path: "/joinUs", component: JoinUsPage },
  { path: "/newsAndUpdates", component: NewsAndUpdates },
  { path: "/newsAndUpdates/:slug", component: NewsView },
  { path: "/registeredPersons", component: RegisteredPersons },
  { path: "*", component: NotFound },
];

const privateRoutes = [
  { path: "/", component: Dashboard },
  { path: "/products", component: ProductsList },
  { path: "/products/:id", component: SingleProduct },
  { path: "/orders", component: OrdersPage },
  { path: "*", component: NotFound },
];

const redirectTo = (path) => {
  window.location.assign(`/${path}`);
};
export { publicRoutes, privateRoutes, redirectTo };
