import React from "react";
import { Avatar, CardActionArea, Typography } from "@mui/material";
export default function AvatarComponents({ avatar, title, role, index }) {
  return (
    <div className="col-md-4 mb-2">
      <CardActionArea
        onClick={() => window.location.assign(`/theTeam/${index}`)}
      >
        <div className="d-flex justify-content-center">
          <Avatar
            sx={{ width: 200, height: 200, marginBottom: 2 }}
            src={avatar}
          />
        </div>
        <div>
          <Typography
            textAlign={"center"}
            textTransform="uppercase"
            variant="body1"
          >
            {title}
          </Typography>
          <div className="text-center">
            <Typography variant="caption">{role}</Typography>
          </div>
        </div>
      </CardActionArea>
    </div>
  );
}
